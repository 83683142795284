import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-future-term',
  templateUrl: './future-term.page.html',
  styleUrls: ['./future-term.page.scss'],
})
export class FutureTermPage implements OnInit {

  constructor(private router: Router,
    private location: Location) {

  }

  ngOnInit() {
  }

  tomorrowsTerm() {
    //console.log("abcd");
    //this.location.back();
    //this.router.navigate(['/home/dashboard']);
    this.location.back();
  }


}
