import { Component, OnInit } from '@angular/core';

import { ToastController } from '@ionic/angular';

import { ActivatedRoute } from '@angular/router'
import { LoadingController } from '@ionic/angular';
import { RestApiService } from 'src/app/services/rest-api.service';
import { Router } from '@angular/router';
import { MyTermStorageService } from 'src/app/services/myTermStorage.service';
import { DatePipe } from '@angular/common';
import { TermsPage } from '../terms/terms.page';
import { Subscription, interval } from 'rxjs';


@Component({
  selector: 'app-definition',
  templateUrl: './definition.page.html',
  styleUrls: ['./definition.page.scss'],
})
export class DefinitionPage implements OnInit {
  panelOpenState = false;
  todayDate = Date.now();
  presentDate = Date.now();
  futureDate = Date.now() + (1000 * 24 * 60 * 60);
  name: string;
  definition: string;
  data: any = [];
  getId: any;
  defTitle: any;
  apiRelavance: string;
  urlPath: String;
  id: number;
  title: any;
  click: number = 1;
  flag: string = "abc";
  priorId: number = 100;
  context: any;
  categories: any = [];
  references: any = [];
  storageId: number = 1;
  iconSave: String = "";
  myTerms: String[] = [];
  randomId: any;
  myTermsData: any = [];
  myTermsSet: Set<string> = new Set();
  length: number = 0;
  reload = 0;
  dateBasedTerm: string;
  datePipeString: string;
  definitionlengthdisplay: number = 500;
  relavancelengthdisplay: number = 140;
  deviceWidth: number;
  definitionlength: number;
  relavancelength: number;
  categoryId:any;

  private updateSubscription: Subscription;

  constructor(public route: ActivatedRoute,
    public loadingCntrl: LoadingController,
    public toastCntrl: ToastController,
    private restApi: RestApiService,
    private router: Router,
    private storageService: MyTermStorageService,
    private toastController: ToastController,
    private datePipe: DatePipe,
  ) {

    this.urlPath = this.router.url;
    //this.getId = this.route.snapshot.queryParamMap.get('name');
    this.route.queryParams.subscribe(params => {
      this.getId = params['search'];
      console.log("getId:" + this.getId);
    });

    this.ngOnInit();

    this.datePipeString = datePipe.transform(Date.now());
    console.log(this.datePipeString);

    if (this.urlPath == "/home/randomdefinition") {
      this.title = "Random Term";
      this.restApi.getRandomTermId().subscribe(termid => {
        this.randomId = termid;
        console.log("randomid:" + termid);
        this.restApi.getTermById(this.randomId).subscribe(data => {
          this.data = data;
          this.categories = this.data.categoryList;
          this.apiRelavance = this.data.relavance;
          this.definition = this.data.definition;
          this.definitionlength = this.definition.length;
          this.relavancelength = this.apiRelavance.length;
          this.context = this.data.context;
          this.references = this.data.referenceList;
          this.isTermAvaialbleInStorage(this.data.term);
          this.categoryId = 2;
          this.relavanceLengthChecking(this.relavancelength, this.relavancelengthdisplay);
        })
      });

    } else if (this.urlPath == "/home/todayTerm") {
      this.title = "Today's Term";

      this.restApi.getRandomTermByDate(datePipe.transform(Date.now())).subscribe(data => {
        this.dateBasedTerm = data.termName;
        //console.log("dateterm:" + this.dateBasedTerm);
        this.restApi.getOneTermBySearch(this.dateBasedTerm).subscribe(data => {
          this.data = data;
          this.categories = this.data.categoryList;
          this.definition = this.data.definition;
          this.definitionlength = this.definition.length;
          this.categoryId = 2;
          this.apiRelavance = this.data.relavance;
          this.relavancelength = this.apiRelavance.length;
          this.context = this.data.context;
          this.references = this.data.referenceList;
          this.isTermAvaialbleInStorage(this.dateBasedTerm);
          this.relavanceLengthChecking(this.relavancelength, this.relavancelengthdisplay);
        })
      });

    }

    else {
      this.title = "Definition";
      this.route.queryParams.subscribe(params => {
        this.getId = params['search'];
        this.categoryId = params['categoryId'];
        console.log("getIddef:" + this.getId);
        this.isTermAvaialbleInStorage(this.getId);
        console.log("categoryIdIn Def:"+this.urlPath);
        console.log("categoryId:"+this.categoryId);
        if(this.categoryId==undefined || null) {
          this.categoryId = 1;
        }
        this.restApi.getTermSearchByCategory(this.getId,this.categoryId).subscribe(data => {
          this.data = data;
          this.categories = this.data.categoryList;
          this.definition = this.data.definition;
          this.definitionlength = this.definition.length;
          this.apiRelavance = this.data.relavance;
          this.relavancelength = this.apiRelavance.length;
          this.context = this.data.context;
          this.references = this.data.referenceList;

          this.relavanceLengthChecking(this.relavancelength, this.relavancelengthdisplay);
        })
      });

    }
  }

  ngOnInit() {
    //let termsPage = new TermsPage(this.router, this.storageService);
    // termsPage.loadData();

    this.updateSubscription = interval(1000).subscribe(
      (val) => { this.refreshStorage() });


    // this.name = this.route.snapshot.paramMap.get('name');
    //this.isTermAvaialbleInStorage(this.data.term);
    // this.getId = params['search'];
    this.deviceWidth = window.screen.width;


    /**   this.route.queryParams.subscribe(params => {
        this.getId = params['search'];
        console.log("getIddef:" + this.getId);
        this.isTermAvaialbleInStorage(this.getId);
  
      }); */


    //window.location.reload();
  }

  isreadMore = false
  more = true
  readMore = false
  isfilled = true

  nextTermButton() {
    console.log("next term button clicked");
    this.readMore = false;
    this.isreadMore = false;
    this.id = this.id + 1;

    this.restApi.getRandomTermId().subscribe(termid => {
      this.randomId = termid;
      this.restApi.getTermById(this.randomId).subscribe(data => {
        this.data = data;
        this.categories = this.data.categoryList;
        this.definition = this.data.definition;
        this.definitionlength = this.definition.length;

        this.apiRelavance = this.data.relavance;
        this.relavancelength = this.apiRelavance.length;
        this.context = this.data.context;
        this.references = this.data.referenceList;
        this.isTermAvaialbleInStorage(this.data.term);


        this.relavanceLengthChecking(this.relavancelength, this.relavancelengthdisplay);
      })
    });
  }

  relavanceLengthChecking(relavancelength: number, relavancelengthdisplay: number) {
    console.log("relavancelength:" + this.relavancelength);
    console.log("relavancelengthdisplay:" + this.relavancelengthdisplay);
    var counter: number = 0;

    if (this.relavancelength - this.relavancelengthdisplay <= 25) {
      this.relavancelengthdisplay = this.relavancelength;
    }

    if (this.relavancelength > this.relavancelengthdisplay) {
      console.log("relavancelengthinsideloop:" + this.relavancelength);
      console.log("charqwe:" + this.apiRelavance.charAt(this.relavancelengthdisplay));


      while (counter < 30 &&
        this.apiRelavance.charAt(this.relavancelengthdisplay) != ' ' &&
        this.apiRelavance.charAt(this.relavancelengthdisplay) != '.' &&
        this.apiRelavance.charAt(this.relavancelengthdisplay) != ',' &&
        this.relavancelengthdisplay < this.relavancelength) {

        counter = counter + 1;
        console.log("counter:" + counter);
        console.log("apirelavance:" + this.apiRelavance);
        console.log("char:" + this.apiRelavance.charAt(this.relavancelengthdisplay) + "X");
        this.relavancelengthdisplay = this.relavancelengthdisplay + 1;
      }



    }
  }

  earlierTermButton() {
    this.title = "Prior Term";
    this.todayDate = this.todayDate - (1000 * 24 * 60 * 60);
    this.priorOrFutureDate();
    /**  if (this.todayDate == this.presentDate) {
       this.dateCheckingTerm(this.datePipe.transform(this.todayDate));
     }
 
     if (this.todayDate > this.presentDate) {
       this.priorId = this.priorId - 1;
       this.dateCheckingTerm(this.datePipe.transform(this.todayDate));
     }
 
     if (this.todayDate < this.presentDate) {
       this.priorId = this.priorId - 1;
       this.dateCheckingTerm(this.datePipe.transform(this.todayDate));
     } */
    this.dateValidation(this.todayDate, this.presentDate);
    this.iconSave = "star-outline";

  }

  futureTermButton() {
    console.log("future term button clicked");
    this.title = "Tomorrow's Term";
    this.todayDate = this.todayDate + (1000 * 24 * 60 * 60);
    this.priorOrFutureDate();
    /**  if (this.todayDate > this.presentDate) {
       this.dateCheckingTerm(this.datePipe.transform(this.todayDate));
     }
     if (this.todayDate == this.presentDate) {
       this.dateCheckingTerm(this.datePipe.transform(this.todayDate));
     }
 
     if (this.todayDate < this.presentDate) {
       this.priorId = this.priorId - 1;
       this.dateCheckingTerm(this.datePipe.transform(this.todayDate));
     } */
    this.dateValidation(this.todayDate, this.presentDate);

  }

  priorOrFutureDate() {
    if (this.todayDate == this.presentDate) {
      this.title = "Today's Term";
    } else if (this.todayDate < this.presentDate) {
      this.title = "Prior Term";
    } else if (this.todayDate > this.futureDate) {
      this.title = "Future Term";
      this.router.navigate(['/home/future-term']);
      this.title = "Tomorrow's Term";
      this.todayDate = this.todayDate - (1000 * 24 * 60 * 60);
    } else if (this.todayDate > this.presentDate) {
      this.title = "Tomorrow's Term";
    }
  }

  async showToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  favoriteTerm(term: string) {
    if (!this.isFavoriteTerm(term)) {
      this.storageService.addData(term);
      this.showToast("Added to my terms successfully");
      this.iconSave = "star";
    } else {
      this.deleteTerm(term);
      this.iconSave = "star-outline";
    }
  }

  deleteTerm(term: string) {
    this.storageService.deleteData(term);
    this.showToast("Deleted from my terms successfully");
    this.iconSave = "star-outline";
  }

  isFavoriteTerm(term: string): boolean {

    this.storageService.getData().then(terms => {
      this.myTerms = terms;
    });
    if (this.myTerms) {
      return this.myTerms.includes(term);
    }
  }

  refreshStorage() {
    this.isTermAvaialbleInStorage(this.data.term);
  }

  isTermAvaialbleInStorage(term: string) {

    if (this.isFavoriteTerm(term)) {

      this.iconSave = "star";
    }
    if (!this.isFavoriteTerm(term)) {
      this.iconSave = "star-outline";
    }
  }


  dateCheckingTerm(date: string): any {
    console.log("checkeddate:" + date);
    this.restApi.getRandomTermByDate(date).subscribe(data => {
      this.dateBasedTerm = data.termName;
      console.log("dateterm:" + this.dateBasedTerm);
      this.restApi.getOneTermBySearch(this.dateBasedTerm).subscribe(data => {
        this.data = data;
        this.categories = this.data.categoryList;
        this.definition = this.data.definition;
        this.definitionlength = this.definition.length;
        this.apiRelavance = this.data.relavance;
        this.context = this.data.context;
        this.references = this.data.referenceList;
        this.isTermAvaialbleInStorage(this.dateBasedTerm);
      })
    });
  }

  dateValidation(todayDate: any, presentDate: any) {
    if (todayDate > presentDate) {
      this.dateCheckingTerm(this.datePipe.transform(this.todayDate));
    }
    if (todayDate == presentDate) {
      this.dateCheckingTerm(this.datePipe.transform(this.todayDate));
    }

    if (todayDate < presentDate) {
      this.priorId = this.priorId - 1;
      this.dateCheckingTerm(this.datePipe.transform(this.todayDate));
    }
  }

  onResize() {
    window.location.reload();
  }

  loadTermsFromStorage() {
    var start = new Date().getTime();
    this.storageService.getData().then(terms => {
      if (terms) {
        this.myTerms = terms;
        this.length = terms.length;
      }
    });
    var end = new Date().getTime();
    var time = end - start;
  }

  goToDefinition(name: string, categoryId:number) {
    //this.router.navigate(['/home/definition/' + name])
    this.router.navigate(['/home/definition'], { queryParams: { search: name,categoryId:categoryId } })
  }

}
